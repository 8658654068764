<template>
    <div @click="toggleChat">
        <fa-icon :icon="['far', 'life-ring']" class="mr-3 text-2xl cursor-pointer text-gray-600" />
    </div>
</template>
<script>
export default {
    methods: {
        toggleChat() {
            if (!window.$crisp) return;

            if (window.$crisp.is('chat:hidden')) {
                window.$crisp.push(['do', 'chat:show']);
                window.$crisp.push(['do', 'chat:open']);
                return;
            }

            window.$crisp.push(['do', 'chat:hide']);
        },
    },
};
</script>
